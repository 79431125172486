<script setup lang="ts">
import { computed, nextTick, onMounted, type Ref, ref } from 'vue'
import { default as PTag } from 'primevue/tag'
import type { Quiz } from '@/assets/ts/classes/progress/quiz'
import { LearningEnums } from '@/assets/ts/types/learning/enums'
import MultipleChoiceAnswers from '@/views/quiz/blocks/answers/MultipleChoiceAnswers.vue'
import { useQuizStore } from '@/stores/quiz'
import type { Diagnostic } from '@/assets/ts/classes/progress/diagnostic'
import EProgressBar from '@/components/elements/progressBar/EProgressBar.vue'
import SingleChoiceAnswer from '@/views/quiz/blocks/answers/SingleChoiceAnswer.vue'
import TrueFalseAnswers from '@/views/quiz/blocks/answers/TrueFalseAnswers.vue'
import MatchingAnswers from '@/views/quiz/blocks/answers/MatchingAnswers.vue'
import OverlayQuestionAnswered from '@/views/quiz/blocks/overlays/OverlayQuestionAnswered.vue'
import type { IUserAnswer } from '@/assets/ts/types/learning/learningQuiz'
import SequencingAnswers from '@/views/quiz/blocks/answers/SequencingAnswers.vue'
import OverlayNeedHelp from '@/views/quiz/blocks/overlays/OverlayNeedHelp.vue'
import EHelpButton from '@/components/elements/buttons/EHelpButton.vue'
import { Duration } from 'ts-luxon'
import type { IProgressQuestion } from '@/assets/ts/types/progress/Quiz'
import type { Mission } from '@/assets/ts/classes/progress/mission'

const emit = defineEmits(['EndQuiz'])

const props = defineProps<{
  quiz: Quiz
  diagnostic: Diagnostic
  mission: Mission
}>()
const questionToAnswer: Ref<IProgressQuestion | null> = ref(null)
const showOverlay = ref(false)
const showCorrectAnswers = ref(false)
const answerIsCorrect = ref(false)
const loadingQuestion = ref(false)
const showHelp = ref(false)
const answerDuration = ref(0)
const feedbackLabel = ref('')

onMounted(() => {
  if (props.quiz.quizType === LearningEnums.QuizType.DIAG) {
    questionToAnswer.value = props.quiz.lastUnansweredQuestion ?? null
  } else {
    questionToAnswer.value = props.quiz.questions[0]
  }
  answerDuration.value = Date.now()
})

// COMPUTED
const answerComponent = computed(() => {
  switch (questionToAnswer.value?.type) {
    case LearningEnums.QuestionType.MULTIPLE_CHOICE:
      return MultipleChoiceAnswers
    case LearningEnums.QuestionType.SINGLE_CHOICE:
      return SingleChoiceAnswer
    case LearningEnums.QuestionType.TRUE_FALSE:
      return TrueFalseAnswers
    case LearningEnums.QuestionType.MATCHING:
      return MatchingAnswers
    case LearningEnums.QuestionType.SEQUENCING:
      return SequencingAnswers
    default:
      return MultipleChoiceAnswers
  }
})

const feedbackText = computed(() => {
  return answerIsCorrect.value
    ? questionToAnswer.value?.feedbacks.correct
    : questionToAnswer.value?.feedbacks.wrong
})

// METHODS
const { sendAnsweredQuestion, sendCompletedQuiz } = useQuizStore()

const handleAnswer = async (userAnswer: IUserAnswer) => {
  answerDuration.value = Math.round((Date.now() - answerDuration.value) / 1000)
  const duration = Duration.fromObject({ seconds: answerDuration.value }).toISO() as string
  answerIsCorrect.value = userAnswer.isCorrect

  if (!questionToAnswer.value) return

  if (props.quiz.quizType === LearningEnums.QuizType.DIAG) {
    await sendAnsweredQuestion(props.quiz, questionToAnswer.value, userAnswer, duration)
  } else {
    const task = props.mission.getTaskByQuizId(props.quiz.quizId)
    await sendAnsweredQuestion(
      props.quiz,
      questionToAnswer.value,
      userAnswer,
      duration,
      task.grainId
    )
  }

  if (props.quiz.quizType === LearningEnums.QuizType.DIAG) {
    showOverlay.value = true

    setTimeout(() => {
      showOverlay.value = false

      if (props.quiz.lastUnansweredQuestion) {
        nextQuestion()
      } else {
        handleQuizComplete()
      }
    }, 2000)
  } else {
    feedbackLabel.value = userAnswer.feedbackLabel ?? ''
    showCorrectAnswers.value = true
  }
}

const nextQuestion = () => {
  loadingQuestion.value = true
  if (props.quiz.quizType === LearningEnums.QuizType.DIAG) {
    questionToAnswer.value = props.quiz.lastUnansweredQuestion ?? null
  } else {
    if (!questionToAnswer.value) return

    if (props.quiz.questions.length === questionToAnswer.value.order) {
      handleQuizComplete()
      return
    }

    questionToAnswer.value = props.quiz.getNextQuestion(questionToAnswer.value.order) ?? null
    showCorrectAnswers.value = false
    feedbackLabel.value = ''
  }
  answerDuration.value = Date.now()
  nextTick(() => (loadingQuestion.value = false))
}

const handleQuizComplete = () => {
  props.quiz.completeQuiz()
  if (props.quiz.quizType === LearningEnums.QuizType.DIAG) sendCompletedQuiz(props.quiz)
  else {
    const task = props.mission.getTaskByQuizId(props.quiz.quizId)
    sendCompletedQuiz(props.quiz, task.grainId)

    if (props.quiz.quizType === LearningEnums.QuizType.POST)
      task.setScore(Math.round(Number(props.quiz.scaled) * 5))
  }
  emit('EndQuiz')
}
</script>

<template>
  <div class="flex justify-between items-center px-4 py-2 bg-white quiz">
    <p class="text-primary">{{ quiz.moduleTitle }}</p>
    <div class="flex gap-2" v-if="quiz.quizType === LearningEnums.QuizType.DIAG">
      <div
        v-for="quiz in diagnostic.quizzesToAtemptCount"
        :key="quiz"
        class="rounded-full h-3 w-3 bg-surface-blue-500"
      />
    </div>
  </div>
  <e-progress-bar
    :max="quiz.questions.length"
    :value="questionToAnswer?.order ?? 0"
    :rounded="false"
    :colors="{ progress: 'var(--variant-pink)' }"
  />
  <div
    class="flex flex-col items-center justify-start bg-surface-blue-500 flex-grow px-4 pt-6 pb-24"
  >
    <div class="w-full flex-col items-center mb-6 max-w-72">
      <p class="font-bold text-center question">{{ questionToAnswer?.label }}</p>
      <p
        v-if="questionToAnswer?.type === LearningEnums.QuestionType.MULTIPLE_CHOICE"
        class="italic text-center"
      >
        (Plusieurs réponses possibles)
      </p>
    </div>

    <component
      v-if="!loadingQuestion && questionToAnswer"
      :is="answerComponent"
      :answers-content="questionToAnswer?.answers"
      :show-correct-answers="showCorrectAnswers"
      @answer="(answer: IUserAnswer) => handleAnswer(answer)"
      @next="nextQuestion"
    />

    <div v-if="showCorrectAnswers" class="mt-8 flex flex-col justify-start gap-2 w-full">
      <p-tag
        class="text small text-white self-center px-2 py-2 rounded-2xl"
        rounded
        :class="answerIsCorrect ? 'bg-green-600' : 'bg-red-500'"
      >
        <i class="pi" :class="answerIsCorrect ? 'pi-check' : 'pi-times'" />
        {{ answerIsCorrect ? 'Parfait !' : feedbackLabel || 'Mauvaise réponse !' }}
      </p-tag>
      <template v-if="feedbackText">
        <h3 class="heading medium font-bold">L'explication</h3>
        {{
          answerIsCorrect ? questionToAnswer?.feedbacks.correct : questionToAnswer?.feedbacks.wrong
        }}
      </template>
    </div>

    <div class="fixed bottom-4 right-4">
      <e-help-button :action="() => (showHelp = true)" size="large" />
    </div>
  </div>

  <transition>
    <overlay-question-answered v-if="showOverlay" :correct="answerIsCorrect" />
  </transition>
  <overlay-need-help
    v-if="showHelp && questionToAnswer"
    :type="questionToAnswer.type"
    @close="showHelp = !showHelp"
  />
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
